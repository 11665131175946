import type { ImageCompressionOptions, Destroy } from '@/src/lib/image-compression-field'

const DEFAULT_OPTIONS: ImageCompressionOptions = {
  maxWidthOrHeight: 2048,
  maxSizeMB: 2,
  initialQuality: 0.9
}

type State = {
  destroy: Destroy
}
const states = new WeakMap<ImageCompression, State>()

export class ImageCompression extends HTMLElement {
  async connectedCallback() {
    const imageCompressionField = (await import('@/src/lib/image-compression-field')).imageCompressionField
    const destroy = imageCompressionField(this, DEFAULT_OPTIONS)
    states.set(this, { destroy })
  }

  disconnectedCallback(): void {
    states.get(this)?.destroy()
  }
}

declare global {
  interface Window {
    ImageCompression: typeof ImageCompression
  }
}

if (!window.customElements.get('image-compression')) {
  window.ImageCompression = ImageCompression
  window.customElements.define('image-compression', ImageCompression)
}

import "bootstrap"
import 'iconify-icon'
import '@oddcamp/cocoon-vanilla-js'
import '@sonicgarden/bs-tooltip-element'
import '@sonicgarden/autosize-textarea-element'
import '@sonicgarden/auto-submit-form-element'
import '@sonicgarden/leave-check-form-element'
import '@/src/elements/removable-element'
import '@/src/elements/translate-fields'
import '@/src/elements/image-compression'
import { start } from '@sonicgarden/rails-ujs-compat'
import { startFlash } from '@/src/lib/flash'
import { initPrint } from '@/src/lib/print'
import { initMobileMenu } from '@/src/lib/mobile-menu'

start()
startFlash()
initPrint()
initMobileMenu()
